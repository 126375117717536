.menu-item-styles {
  color: white;
  transition: background-color 200ms;
  :hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: white !important;
  }
  a {
    transition: background-color 200ms;
    border-radius: 5px;
    width: 195px;
    :hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
}

.menu-item-link {
}
