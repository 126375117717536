body {
  overflow-x: hidden ;
  margin: 0;
  height: 100%;
}

.full-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
} 