// BIG DESKTOP VARIABLES


// DESKTOP VARIABLES
$dt-banner-height: 36vh;
$dt-background-offset: 32vh;
$dt-col-offset: -13vh;
$dt-background-right: 0;
$dt-banner-radius: 50% 50% 70% 40% / 0 0 85% 71%;

.banner {
    position: relative;
}

.banner-img {
    background-image: url("./../../img/main-banner.jpg");
    background-position: right $dt-background-right bottom $dt-background-offset;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: $dt-banner-height;
    width: 103%;
    margin-left: -1.5%;
    position: relative;
    top: 0; left: 0;
    overflow: hidden;
    border-radius: $dt-banner-radius;
}

.banner-layer {
    position: absolute;
    background-color: rgba(0,48,60,.8);
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        width: 65%;
        max-width: 600px;
    }
}

.banner-col {
    width: 26vh;
    position: absolute;
    bottom: $dt-col-offset;
    right: 20%;
}

@media only screen and (max-width: 1720px) {
    $dt-background-right: -16vw;

    .banner-img {
        background-position: right $dt-background-right bottom $dt-background-offset;
    }
}

@media only screen and (max-width: 1470px) {

    $dt-col-offset: -12vh;

    .banner-col {
        width: 24vh;
        bottom: $dt-col-offset;
    }
}

@media only screen and (max-width: 1105px) {

    $dt-col-offset: -11vh;
    $dt-banner-radius: 50% 50% 70% 40% / 0 0 85% 71%;

    .banner-img {
        border-radius: $dt-banner-radius;
    }

    .banner-col {
        width: 22vh;
        bottom: $dt-col-offset;
    }

}

@media only screen and (max-width: 500px ) {
    
    $dt-col-offset: -8vh;
    $dt-banner-height: 24vh;

    $dt-background-right: -75vw;
    $dt-background-offset: 45vh;

    .banner-img {
        height: $dt-banner-height;
        background-position: right $dt-background-right bottom $dt-background-offset;
        background-size: initial;
    }

    .banner-col {
        width: 16vh;
        bottom: $dt-col-offset;
    }
}