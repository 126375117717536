@import '../../../../assets/Variables/variables';

#system_info {
    display: flex;
    flex: 0.9;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 1vh;
}

.yellow_small {
    color: $color-solesta-yellow;
    font-weight: bold !important;
    font-size: 1.5rem;
}

.b1 {
    font-weight: bold !important;
    font-size: 1.1rem !important;
    font-style: italic !important;
}

.b2 {
    font-weight: bold !important;
    font-size: 1rem !important;
    font-style: italic !important;
}

#water_temp {
    font-weight: bold;
    color: $color-solesta-yellow;
}

#showers-and-system {
    padding: 30px;
    border: 1px solid rgb(214, 214, 214);
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 7px;
}

#showers {
    margin-bottom: 30px;

    p, h5 {
        display: inline
    }
    h5 {margin: 0 7px}
}

#douche {
    font-weight: bold;
    color: $color-solesta-yellow;
}

#navbar-wrapper {
    width: 100%;
    max-width: 700px;
    margin: 0 auto 30px;
}

@media only screen and (max-width: 500px) {
    #system_info {
        flex: 0.85;
        margin-top: 7vh;
    }

    #showers-and-system {
        padding: 20px 30px;
    }
}