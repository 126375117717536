@import './../../Variables/variables';

#footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.1;
    padding: 10px 0;
    width: 100%;
    background-color: $color-solesta-blue;
}

#footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 800px;
}

.footer-btn {
    background-image: linear-gradient(to right, #f9cb0a, #f18e1f);
    color: $color-solesta-blue;
    border-width: 0;
}

@media only screen and (max-width: 500px) {
    #footer {
        flex: 0.15;
    }
}
