@import "_variables.scss";

.form_root {
  margin-top: 36px;
  padding-bottom: 36px;
}

.checkbox_container {
  background-color: #eef3f6c4;
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  width: 200px;

  p {
    font-size: 0.75em;
    font-weight: 600;
    color: $defaultBlue;
  }
}

.form_checkboxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.textfield_container {
  background-color: #eef3f6c4;
  border-radius: 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;

  p {
    font-size: 0.75em;
    font-weight: 600;
    color: $defaultBlue;
  }
}

.dropdown_container {
  background-color: #eef3f6c4;
  border-radius: 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;

  .dropdown_label {
    flex: 0.4;
    font-size: 0.75em;
    font-weight: 600;
    color: $defaultBlue;
  }
  .dropdown_value {
    font-size: 0.75em;
    color: $defaultBlue;
  }
  div {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
      color: $defaultGreen;
      font-size: 1.8rem;
      margin-top: -4px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.form_textfields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.popover_wrapper {
  padding: 12px;
}

.popover_value {
  font-size: 0.75rem;
  color: $defaultBlue;
  margin: 6px auto;
  padding: 6px 12px;
  border-radius: 12px;
  transition: background-color 200ms;
  &:hover {
    cursor: pointer;
    background-color: #eef3f6c4;
  }
}

.popover_value_disabled {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.26);
  margin: 6px auto;
  padding: 6px 12px;
  border-radius: 12px;
}
